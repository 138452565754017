"use strict";

/**
 * Import dependencies from node_modules
 * see commented examples below
 */
// import 'some-node-module';
// import SomeModule from 'some-node-module';

/**
 * Write any other JavaScript below
 */
+function () {// theme switcher for light/dark mode
  // const themeSwitcher = document.querySelector("#theme-switcher");
  // themeSwitcher.addEventListener("click", (e) => {
  //   if (
  //     "dark" === localStorage.theme ||
  //     (!("theme" in localStorage) && !e.target.checked)
  //   ) {
  //     document.querySelector("html").classList.remove("dark");
  //     localStorage.theme = "light";
  //     themeSwitcher.classList.remove("active");
  //   } else {
  //     document.querySelector("html").classList.add("dark");
  //     localStorage.theme = "dark";
  //     themeSwitcher.classList.add("active");
  //   }
  // });
}();